import cloneDeep from "lodash/cloneDeep";
import { getDisplayExtensionItem } from "~/api/extensions";
import { FieldInfoInterface, useFieldsStore } from "~/entities/field";
import ItemInterface from "~/api/items/entities/ItemInterface";
import { FieldDisplayOptions } from "~/api/field-displays/types";
import { defineFieldDisplayOptions } from "~/api/field-displays/defines";
import { getDefaultFieldDisplayOptions } from "~/api/field-displays/defaults";

const regexDisplayTemplate = /({{.*?}})/g;

/**
 *
 *
 * @param template
 * @param fieldInfo
 * @param item
 * @returns
 * @todo TEST
 */
export const useDisplayTemplateParts = (
  template: string | undefined | null,
  collectionName: string,
  item: ItemInterface,
  fieldInfo: FieldInfoInterface,
): (FieldDisplayOptions | string | null)[] | undefined => {
  const templateExpression: string = !!template
    ? template
    : fieldInfo.meta?.displayOptions?.template ?? "";

  return (
    templateExpression
      .split(regexDisplayTemplate)
      .filter((p) => p)
      .map((part) => {
        if (part.startsWith("{{") === false) return part;

        let fieldKey = part.replace(/{{/g, "").replace(/}}/g, "").trim();
        let fieldKeyBefore = fieldKey.split(".").slice(0, -1).join(".");
        let fieldKeyAfter = fieldKey.split(".").slice(-1)[0];

        if (!fieldKeyBefore) return handleObjectData(collectionName, item, fieldKey);

        // NOTE! this is just pre-check value. fieldKeyBefore may exists only if complex query, like {{collection.field_id}}
        const value = item.getFieldDataByExpression(fieldKey);

        return Array.isArray(value)
          ? handleArrayData(collectionName, item, fieldKeyBefore, fieldKeyAfter)
          : handleObjectData(collectionName, item, fieldKey);
      })
      .map((p) => p ?? null) ?? undefined
  );
};

function handleArrayData(
  collectionName: string,
  item: ItemInterface,
  fieldKeyBefore: string,
  fieldKeyAfter: string,
): FieldDisplayOptions | null {
  const fieldsStore = useFieldsStore();

  const fieldKey = `${fieldKeyBefore}.${fieldKeyAfter}`;
  const fieldInfo = fieldsStore.getField(collectionName, fieldKeyBefore);
  if (!fieldInfo) return null;

  type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };
  const adjustedFieldInfo = cloneDeep<DeepWriteable<FieldInfoInterface>>(
    fieldInfo as DeepWriteable<FieldInfoInterface>,
  );
  if (!adjustedFieldInfo.meta.display) {
    adjustedFieldInfo.meta.display = "related-values";
  }
  if (!adjustedFieldInfo.meta.displayOptions) {
    adjustedFieldInfo.meta.displayOptions = {
      template: `{{${fieldKeyAfter}}}`,
    };
  }

  const fieldDisplay = adjustedFieldInfo.meta.display;

  const fieldDisplayConfig = getDisplayExtensionItem(fieldDisplay);
  if (!fieldDisplayConfig) {
    const defaultDisplayConfig = getDisplayExtensionItem("related-values");
    if (!defaultDisplayConfig) return null;

    return defineFieldDisplayOptions({
      item,
      fieldKey,
      component: defaultDisplayConfig.component,
      fieldInfo: adjustedFieldInfo,
    });
  }

  return defineFieldDisplayOptions({
    item,
    fieldKey,
    component: fieldDisplayConfig.component,
    fieldInfo: adjustedFieldInfo,
  });
}

function handleObjectData(
  collectionName: string,
  item: ItemInterface,
  fieldKey: string,
): FieldDisplayOptions | null {
  const fieldsStore = useFieldsStore();

  // todo processing collection "directus_files"
  const fieldInfo = fieldsStore.getField(collectionName, fieldKey);
  const fieldData = item.getFieldDataByExpression(fieldKey);

  if (fieldData === undefined) return null;
  if (!fieldInfo) return fieldData;

  const fieldDisplay = fieldInfo.meta.display;
  if (!fieldDisplay) {
    return getDefaultFieldDisplayOptions(item, fieldInfo.type, fieldKey);
  }

  const fieldDisplayConfig = getDisplayExtensionItem(fieldDisplay);
  if (!fieldDisplayConfig) {
    return getDefaultFieldDisplayOptions(item, fieldInfo.type, fieldKey);
  }

  return defineFieldDisplayOptions({
    item,
    fieldKey,
    fieldInfo,
    component: fieldDisplayConfig.component,
  });
}

