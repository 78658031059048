import { Component } from "@vue/runtime-core";
import { FieldInfo } from "~/entities/field";
import ItemInterface from "~/api/items/entities/ItemInterface";
import { FieldDisplayOptions } from "./types";
import { defineFieldDisplayOptions } from "./defines";
import FormattedValueVue from "./formatted-value/formatted-value.vue";
import RawDisplay from "./raw/raw-display.vue";

const defaultDisplaysHashMap: Record<FieldInfo["type"], Component> = {
  string: FormattedValueVue,
  integer: FormattedValueVue,
  float: FormattedValueVue,
  decimal: FormattedValueVue,
  text: FormattedValueVue,
  uuid: FormattedValueVue,
};

export const getDefaultFieldDisplayOptions = (
  item: ItemInterface,
  fieldType: string,
  displayFieldKey: string,
): FieldDisplayOptions => {
  if (fieldType in defaultDisplaysHashMap) {
    return defineFieldDisplayOptions({
      item,
      fieldKey: displayFieldKey,
      component: defaultDisplaysHashMap[fieldType],
    });
  }

  // fallback display options
  return defineFieldDisplayOptions({
    item,
    fieldKey: displayFieldKey,
    component: RawDisplay,
  });
};
