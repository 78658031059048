<template>
  <div :class="['render-template', 'render-template--inline']">
    <template v-for="part in displayTemplateParts">
      <DataNull
        v-if="
          part === null ||
          (typeof part === 'object' &&
            part.item.getFieldDataByExpression(part.fieldKey) === null)
        "
      />

      <Suspense v-if="part !== null && typeof part === 'object'">
        <component
          :is="part.component"
          v-bind="{
            collectionName: props.collectionName,
            item: part.item,
            fieldKey: part.fieldKey,
            context: props.context,
            fieldInfo: part.fieldInfo || undefined,
          }"
        ></component>

        <template #fallback>
          <Skeleton height="2rem" width="5rem"></Skeleton>
        </template>
      </Suspense>

      <span v-else>{{ part }}</span>
    </template>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface Props {
    collectionName: string;
    item: _VTI_TYPE_ItemInterface;
    fieldInfo: _VTI_TYPE_FieldInfoInterface;
    defaultTemplate?: string;
    context?: {
      data?: ItemInterface;
    };
  }
  import { watchEffect, shallowRef } from 'vue'
  import Skeleton from 'primevue/skeleton'
  import { FieldDisplayOptions } from '~/api/field-displays/types'
  
  
  import DataNull from '~/components/DataNull/data-null.vue'
  import { useDisplayTemplateParts } from '../model'
  
  const props = defineProps<Props>();
  const displayTemplateParts = shallowRef<
    (FieldDisplayOptions | string | null)[] | undefined
  >(undefined);
  watchEffect(() => {
    displayTemplateParts.value = useDisplayTemplateParts(
      props.defaultTemplate || `{{ ${props.fieldInfo.name} }}`,
      props.collectionName,
      props.item,
      props.fieldInfo,
    );
  });
</script>

<style scoped></style>
entities/field/lib/FieldInfoInterface
